const SERVER_URL = "https://amberwoodre-backend-nodejs-cpmn6gngia-wl.a.run.app/";
// const SERVER_URL = "http://192.168.29.179:8080/";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyAhA8D4GNYY4-4u8vinZJE1AZMUvBbn9Kw";
const IMAGE_URL = "https://storage.googleapis.com/cdn-amberwoodre/";
const AGENT_ID = "645a9836ff97f83953e5a730";
module.exports = {
  SERVER_URL,
  YOUR_GOOGLE_MAPS_API_KEY,
  IMAGE_URL,
  AGENT_ID
};
