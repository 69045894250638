import { useEffect, useState } from "react";
import { getInstagramPosts } from "../service/api";

/* ICONS */
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { motion } from "framer-motion";


const InstagramWall = () => {

  const [instagramWallDataAmberWood, setInstagramWallDataAmberWood] = useState(
    []
  );

  /* USEEFFECT */
  useEffect(() => {
    getInstagramPostsFunction();
  }, []);

  /* GET INSTAGRAM POSTS */
  function getInstagramPostsFunction() {

    const AmberwoodREInstagram = (event) => {
      const obj = {
        postFor: event,
        limit: 4,
      };
      getInstagramPosts(obj).then((res) => {
        console.log("res: ", res);
        if (res?.status) {
          setInstagramWallDataAmberWood(res?.data);
        }
      });
    }
    AmberwoodREInstagram("AMBERWOODRE")
  }

  return (
    <>
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="instagram-wall"
      >
        <div className="container">
          <div className="heading">
            <h2>FOLLOW US ON INSTAGRAM</h2>
            <a
              href="https://instagram.com/amberwoodreinc"
              target="_blank"
              rel="noreferrer"
              className="instagram-link"
            >
              @amberwoodreinc
            </a>
          </div>
          <div className="row mt-4">
            {instagramWallDataAmberWood?.length > 0
              ? instagramWallDataAmberWood?.map((data) => {
                return (
                  <div
                    className="col-xl-3 col-md-6 col-sm-12"
                    key={data?.permalink}
                  >
                    <a
                      href={data?.permalink}
                      target="_blank"
                      aria-label={`Instagram caption ${data?.caption}`}
                      rel="noreferrer"
                      className={`instagram-link`}
                    >
                      <div className="instagram-box">
                        {data?.media_type === "IMAGE" ||
                          data?.media_type === "CAROUSEL_ALBUM" ? (
                          <img
                            src={data?.media_url}
                            alt="Follow Amberwood Real Estate on Instagram"
                          />
                        ) : (
                          <video
                            autoPlay
                            muted
                            loop
                            width={"100%"}
                            style={{ objectFit: "cover" }}
                          >
                            <source src={data?.media_url} type="video/mp4" />
                          </video>
                        )}
                        <div className="bg-overlay">
                          <FaExternalLinkSquareAlt color="#FFF" size="25" />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })
              : false}
          </div>
        </div>
      </motion.section>
      {/* <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="instagram-wall"
      >
        <div className="container">
          <div className="heading">
            <h2>FOLLOW ME ON INSTAGRAM</h2>
            <a
              href="https://instagram.com/lahomeguy"
              target="_blank"
              rel="noreferrer"
              className="instagram-link"
            >
              @lahomeguy
            </a>
          </div>
          <div className="row mt-4">
            {instagramWallData?.length > 0
              ? instagramWallData?.map((data) => {
                return (
                  <div className="col-xl-3 col-md-6 col-sm-12">
                    <a
                      href={data?.permalink}
                      target="_blank"
                      aria-label={`Instagram caption ${data?.caption}`}
                      rel="noreferrer"
                      className={`instagram-link`}
                    >
                      <div className="instagram-box">
                        {data?.media_type === "IMAGE" ||
                          data?.media_type === "CAROUSEL_ALBUM" ? (
                          <img
                            src={data?.media_url}
                            alt="Follow Amberwood Real Estate on Instagram"
                          />
                        ) : (
                          <video
                            autoPlay
                            muted
                            loop
                            width={"100%"}
                            style={{ objectFit: "cover" }}
                          >
                            <source src={data?.media_url} type="video/mp4" />
                          </video>
                        )}
                        <div className="bg-overlay">
                          <FaExternalLinkSquareAlt color="#FFF" size="25" />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })
              : false}
          </div>
        </div>
      </motion.section> */}
    </>
  );
};

export default InstagramWall;
