/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { motion } from "framer-motion";

/* IMAGES */
import BlogPlaceholder from "../../assets/blog-placeholder.webp";

/* ICONS */
import PreviousIcon from "@iconscout/react-unicons/icons/uil-previous";
import CloseIcon from "@iconscout/react-unicons/icons/uil-times";
import NextIcon from "@iconscout/react-unicons/icons/uil-step-forward";

/* APIS */
import { getBlogs } from "../../service/api";
import { useEffect, useState } from "react";

/* REDUX REQUIREMENTS */
import actions from "../../redux/actions/userAction";

/* CONFIG */
import { IMAGE_URL } from "../../config";
import ReactPaginate from "react-paginate";

const BlogBoxLoading = () => {
  return (
    <div className="blog-card">
      <img src={BlogPlaceholder} alt="Blog Placeholder" />
      <div className="details">
        <div className="posting-details">
          <Skeleton className="time-loading" />
        </div>
        <Skeleton className="title-loading" />
      </div>
    </div>
  );
};

const BlogBox = (props) => {
  return (
    <Link
      to={"/blog-detail/" + props?.blogDetails?._id}
      style={{ textDecoration: "none" }}
    >
      <div className="blog-card">
        <img src={IMAGE_URL + props.imgLink} alt={props.blogTitle} />
        <div className="details">
          <div className="posting-details">
            <h2>
              {moment(`${new Date(props?.date)}`).format("DD MMMM, YYYY")}
            </h2>
          </div>
          <h2 className="title">{props.blogTitle}</h2>
        </div>
      </div>
    </Link>
  );
};

const Blogs = () => {
  /* GET USER STATE REDUX VARIABLE */
  const blogCategory = useSelector((state) => state?.user?.blogCategory);
  const dispatch = useDispatch();

  /* STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [totalPages, setTotalPages] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(null);
  const [hasPrevPage, setHasPrevPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [blogsData, setBlogsData] = useState([]);
  const [category, setCategory] = useState(
    blogCategory?.categoryId ? blogCategory?.categoryId : null
  );
  const [categoryName, setCategoryName] = useState(
    blogCategory?.categoryName ? blogCategory?.categoryName : null
  );

  /* GET BLOGS LISTINGS */
  const getBlogsList = () => {
    setLoading(true);
    const obj = {};
    obj.page = page;
    obj.sizePerPage = pageSize;
    if (category) {
      obj.category = category;
    }

    getBlogs(obj)
      .then((res) => {
        if (res?.status) {
          setBlogsData(res?.data?.docs);
          setHasNextPage(res?.data?.hasNextPage);
          setHasPrevPage(res?.data?.hasPrevPage);
          setNextPage(res?.data?.nextPage);
          setPrevPage(res?.data?.prevPage);
          setTotalDocs(res?.data?.totalDocs);
          setTotalPages(res?.data?.totalPages);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });

    return () => {
      setBlogsData([]);
    };
  };

  /* GET BLOGS ONLOAD */
  useEffect(() => {
    getBlogsList();
  }, []);

  useEffect(() => {
    getBlogsList();
  }, [category]);

  useEffect(() => {
    getBlogsList();
  }, [page]);

  /* PAGE CLICK */
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setLoading(true);
    setBlogsData([]);
    setPage(selectedPage);
    console.log(`Page ${selectedPage} clicked.`);
  };

  const handleClear = () => {
    setCategory(null);
    setCategoryName(null);
    dispatch(actions.setBlogDetails(null));
  };

  const metaTitle = "Blogs | Christie Vilano";
  const metaDescription =
    "Delve into a wealth of knowledge and inspiration through our engaging real estate blogs. Our 'Blogs' page is a treasure trove of informative articles, industry insights, and expert advice.";
  const metaImage =
    "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png";

  document.title = metaTitle;

  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", metaImage);
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);

  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[name="twitter:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[name="twitter:image"]')
    .setAttribute("content", metaImage);

  return (
    <section className="blogs">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/general-banner-video-christie.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>BLOGS</h1>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-content"
      >
        <div className="container">
          <div className="row">
            {/* BLOGS LIST */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-9 col-sm-12"
            >
              <div className="row gy-4">
                {category ? (
                  <div className="filters-applied">
                    <p>Filters:</p>
                    <div className="applied-filters-list">
                      <div className="applied-filter">
                        <p>
                          <b style={{ fontWeight: "600" }}>{categoryName}</b>
                        </p>
                        <Button className="close-btn" onClick={handleClear}>
                          <CloseIcon size="14" color="#A08766" />
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {loading ? (
                  <>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <BlogBoxLoading />
                    </div>
                  </>
                ) : (
                  blogsData.map((blog) => {
                    return (
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-12"
                        key={blog.id}
                      >
                        <BlogBox
                          imgLink={blog?.image}
                          blogTitle={blog?.name}
                          date={blog?.startDate}
                          blogDetails={blog}
                        />
                      </div>
                    );
                  })
                )}
              </div>
              <ReactPaginate
                className="blogs-pagination"
                previousLabel={
                  <Button className="blog-pagination-btn">
                    <PreviousIcon color="#323232" size="20" />
                  </Button>
                }
                nextLabel={
                  <Button className="blog-pagination-btn">
                    <NextIcon color="#323232" size="20" />
                  </Button>
                }
                breakLabel={"..."}
                pageCount={totalPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                activeClassName={"active"}
              />
            </motion.div>

            {/* BLOGS SIDEBAR */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className="col-xl-3 col-sm-12"
            >
              <div className="blog-sidebar">
                <h2>Blog Categories</h2>
                <div className="categories-link">
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("644741d5caa40f2e07b6eb7e");
                      setCategoryName("Become a Real Estate Agent");
                    }}
                  >
                    Become a Real Estate Agent
                  </Link>
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("644741e2caa40f2e07b6eb81");
                      setCategoryName("Buyer's Agent");
                    }}
                  >
                    Buyer's Agent
                  </Link>
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("644741e7caa40f2e07b6eb84");
                      setCategoryName("Buying a Home");
                    }}
                  >
                    Buying a Home
                  </Link>
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("644741eccaa40f2e07b6eb87");
                      setCategoryName("Buying and Selling Homes");
                    }}
                  >
                    Buying and Selling Homes
                  </Link>
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("644741f0caa40f2e07b6eb8a");
                      setCategoryName("Home Inspection");
                    }}
                  >
                    Home Inspection
                  </Link>
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("644741f5caa40f2e07b6eb8d");
                      setCategoryName("Listing Agent");
                    }}
                  >
                    Listing Agent
                  </Link>
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("644741fbcaa40f2e07b6eb90");
                      setCategoryName("Real Estate Agent");
                    }}
                  >
                    Real Estate Agent
                  </Link>
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("64474200caa40f2e07b6eb93");
                      setCategoryName("Sell Your Home");
                    }}
                  >
                    Sell Your Home
                  </Link>
                  <Link
                    className="link-item"
                    onClick={() => {
                      setCategory("64474204caa40f2e07b6eb96");
                      setCategoryName("Selling Real Estate");
                    }}
                  >
                    Selling Real Estate
                  </Link>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Blogs;
