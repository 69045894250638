import { motion } from "framer-motion";

const AboutBenny = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="about"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-5 col-lg-5 col-sm-12">
            <img
              src={require("../assets/christie-vilano-profile.webp")}
              alt="Christie Vilano"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <h2>MEET CHRISTIE VILANO</h2>
            <p>
              With two years of experience in real estate, I'm devoted to
              assisting clients in finding their dream homes and achieving their
              financial aspirations through property investments. My
              client-centric approach involves building strong relationships,
              understanding individual needs, and providing personalized
              guidance to make informed decisions.
            </p>
            <p>
              Diligent and detail-oriented, I take pride in my extensive local
              market knowledge and keep up with industry trends. Customer
              service and sales background further enhance my ability to support
              clients emotionally through the complex buying and selling
              process.
            </p>
            <p>
              Beyond real estate, I explore the area, spend time with loved
              ones, and pursue personal interests. Joining the Amberwood Real
              Estate team excites me, and I eagerly await the opportunity to
              collaborate with you. Feel free to contact me for inquiries or
              consultations; I'm here to assist you!
            </p>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default AboutBenny;
