import { motion } from "framer-motion";

const TestimonialBox = (props) => {
  return (
    <div className="testimonial-box">
      <h5>{props.title}</h5>
      <p>{props.description}</p>
      <div className="testimonial-profile">
        <img src={props.userProfile} alt={props.name} />
        <div className="profile-details">
          <h5>{props.name}</h5>
          <p>{props.userRole}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="testimonials"
    >
      <div className="container">
        <div className="heading">
          <h2>WHAT CLIENTS SAY ABOUT ME</h2>
          <h5>
            Read what our satisfied clients have to say about their experience
            working with me.
          </h5>
        </div>
        <div className="row mt-2 gy-4 justify-content-center">
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Best Selling Experience"
              description="Christie helped with the sale of our condo and it was a seamless process. She is professional and resourceful, and it is clear that she is passionate about her business and she does everything she can to satisfy her clients."
              name="Jaclyn Adachi"
              userProfile={require("../assets/testimonial-01.jpg")}
              userRole="Real Estate Seller"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Great Experience"
              description="Christie and her team were very professional and got the job done. Our house was on the market for less then a week and was sold above asking price. We would highly recommend her. WE LOVE HER!!! "
              name="Arlie Bautista"
              userProfile={require("../assets/testimonial-02.webp")}
              userRole="Real Estate Seller"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Easy Selling Experience"
              description="Christie Vilano is a professional in her field of real state buying and selling. She is very knowledgeable and can sell your home or purchase a home and she will get it done right in a short period time frame."
              name="Dennis Pasquel"
              userProfile="https://images.unsplash.com/photo-1580894732444-8ecded7900cd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Seller"
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Testimonials;
