/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import Select from "react-select";
import ShowMoreText from "react-show-more-text";
import { useFormik } from "formik";
import * as yup from "yup";
import { motion } from "framer-motion";

/* GOOGLE MAP IMPORTS */
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  DistanceMatrixService,
} from "@react-google-maps/api";
import mapStyles from "./components/map-styles";

/* IMAGES */
import AmberwoodBigPlaceholder from "../../assets/amberwood-big-placeholder.png";

/* ICONS */
import { FaMapMarkerAlt } from "react-icons/fa";
import UilAngleLeft from "@iconscout/react-unicons/icons/uil-angle-left";

/* SAMPLE DATA */
import { contactUs, getMLSById } from "../../service/api";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";

/* PACKAGE STYLES */
import "react-toastify/dist/ReactToastify.css";
import { AGENT_ID } from "../../config";

const termOptions = [
  { value: "10", label: "10 Years" },
  { value: "15", label: "15 Years" },
  { value: "30", label: "30 Years" },
];

/* GOOGLE MAP API CONSTS */
const libraries = ["places"];
const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

/* OPTIONS */
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  DistanceMatrixService,
};

const PropertyDetails = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  console.log("SELECTED PROPERTY -->: ", selectedItem);

  /* GOOGLE MAP CENTER */
  const center = {
    lat: selectedItem?.Latitude,
    lng: selectedItem?.Longitude,
  };

  /* GOOGLE MAP REPLATED STATES START */
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAhA8D4GNYY4-4u8vinZJE1AZMUvBbn9Kw",
    libraries,
  });

  const location = useLocation();
  console.log("location: ", location);

  /* STATES */
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);

  /* ON GOOGLE MAP CLICK */
  const onMapClick = useCallback((e) => {
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        data: e,
        time: new Date(),
      },
    ]);
  }, []);

  const mapRef = React.useRef();

  /* ON GOOGLE MAP LOAD */
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const { mlsId, mlsKey } = useParams();
  console.log("mlsId,mlsKey: ", mlsId, mlsKey);
  const getMlsId = mlsId?.split("&");
  console.log("getMlsId: ", getMlsId);
  const getMlsKey = mlsKey?.split("&");
  console.log("getMlsKey: ", getMlsKey);

  /* DEFAUULT PROPS FOR MAP */
  const defaultProps = {
    center: {
      lat: selectedItem?.Latitude,
      lng: selectedItem?.Longitude,
    },
    zoom: 15,
  };

  /* USE EFFECT */
  useEffect(() => {
    if (getMlsId?.length > 0 && getMlsKey?.length > 0) {
      getMLSById(getMlsId[0], getMlsKey[0]).then((res) => {
        console.log("res: ", res);
        if (res?.status && res?.data) {
          setSelectedItem(res?.data?.value[0]);
        }
      });
    }
  }, [getMlsId?.length, getMlsKey?.length]);

  const firstImage = selectedItem?.Media?.filter((img) =>
    img?.MediaURL?.includes(
      "https://api-trestle.corelogic.com/trestle/Media/CRMLS/Property/PHOTO-jpeg/" +
        selectedItem?.ListingKey +
        "/1/"
    )
  );

  useEffect(() => {
    if (
      selectedItem?.UnparsedAddress !== undefined &&
      selectedItem?.City !== undefined &&
      selectedItem?.StateOrProvince !== undefined &&
      selectedItem?.PostalCode !== undefined
    ) {
      document.title =
        selectedItem?.UnparsedAddress +
        ", " +
        selectedItem?.City +
        ", " +
        selectedItem?.StateOrProvince +
        ", " +
        selectedItem?.PostalCode;
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute(
          "content",
          selectedItem?.UnparsedAddress +
            ", " +
            selectedItem?.City +
            ", " +
            selectedItem?.StateOrProvince +
            ", " +
            selectedItem?.PostalCode
        );
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", selectedItem?.PublicRemarks);
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", firstImage[0]?.MediaURL);
      document
        .querySelector('meta[property="og:url"]')
        .setAttribute("content", window.location.href);
    }
  }, [selectedItem]);

  /* MAP MARKER */
  const MapMarker = () => {
    return (
      <div className="p-relative">
        <div className="map-marker" />
        <FaMapMarkerAlt color="#008080" size="30" className="marker-icon" />
      </div>
    );
  };

  /* MAP OPTIONS */
  const mapOptions = (maps) => {
    return {
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: "greedy",
      scaleControl: true,
      disableDoubleClickZoom: true,
      minZoom: 11,
      fullscreenControl: false,
      styles: [
        {
          featureType: "poi.business",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
    };
  };

  function handleZoomChanged(e) {
    console.log("getMarginBounds", e?.marginBounds);
  }

  /* MORTGAGE CALCULATOR */
  const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState("0");
  const [principalPlusInterest, setPrincipalPlusInterest] = useState("0");
  const [privateMortgageInsurance, setPrivateMortgageInsurance] = useState("0");
  const [showTaxes, setShowTaxes] = useState("0");

  const [purchasePrice, setPurchasePrice] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [loanTerm, setLoanTerm] = useState({ value: "30", label: "30 Years" });
  const [interestRate, setInterestRate] = useState("");
  const [propertyTax, setPropertyTax] = useState("");
  const [insurance, setInsurance] = useState("");
  const [hoaFees, setHoaFees] = useState("");

  const [formSubmit, setFormSubmit] = useState(false);

  /* VALIDATION SCHEMA */
  const validationSchema = yup.object().shape({
    purchasePrice: yup.string().required("Purchase Price is required"),
    downPayment: yup.string().required("Down Payment is required"),
    loanTerm: yup.object().required("Loan Term is required"),
    interestRate: yup.string().required("Interest Rate is required"),
    propertyTax: yup.string().required("Property Tax is required"),
    insurance: yup.string().required("Insurance is required"),
    hoaFees: yup.string().required("Montly HOA Fees is required"),
  });

  /* FORM HANDLING */
  const formik = useFormik({
    initialValues: {
      purchasePrice: "",
      downPayment: "",
      loanTerm: { value: "30", label: "30 Years" },
      interestRate: "",
      propertyTax: "",
      insurance: "",
      hoaFees: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormSubmit(true);
      setPurchasePrice(values?.purchasePrice);
      setDownPayment(values?.downPayment);
      setLoanTerm(values?.loanTerm);
      setInterestRate(values?.interestRate);
      setPropertyTax(values?.propertyTax);
      setInsurance(values?.insurance);
      setHoaFees(values?.hoaFees);
    },
    onReset: () => {
      setFormSubmit(false);
      setPurchasePrice("");
      setDownPayment("");
      setLoanTerm({ value: "30", label: "30 Years" });
      setInterestRate("");
      setPropertyTax("");
      setInsurance("");
      setHoaFees("");
      setPrincipalPlusInterest(0);
      setPrivateMortgageInsurance(0);
      setPrivateMortgageInsurance(0);
      setEstimatedMonthlyPayment(0);
      setShowTaxes(0);
    },
  });

  /* USE EFFECT */
  useEffect(() => {
    if (formSubmit) {
      handleFormSubmit();
    }
  }, [
    formSubmit,
    purchasePrice,
    downPayment,
    loanTerm,
    interestRate,
    propertyTax,
    insurance,
    hoaFees,
  ]);

  /* FORMULA */
  const calculateMortgage = () => {
    const principal = parseFloat(purchasePrice) - parseFloat(downPayment);
    const rate = parseFloat(interestRate) / 100 / 12;
    const term = parseFloat(loanTerm.value) * 12;
    const monthlyTax = parseFloat(propertyTax) / 12;
    const monthlyInsurance = parseFloat(insurance) / 12;
    const monthlyHOAFees = parseFloat(hoaFees);

    /* ESTIMATED MONTHLY PAYMENT */
    const monthlyPayment =
      (principal * rate * Math.pow(1 + rate, term)) /
        (Math.pow(1 + rate, term) - 1) +
      monthlyTax +
      monthlyInsurance +
      monthlyHOAFees;

    /* PRIVATE MORTGAGE INSURANCE */
    const pmi = principal / purchasePrice;
    const totalPMI = pmi * 100;

    /* PRINCIPAL AND INTEREST */
    const showPrincipal = monthlyPayment - monthlyTax - monthlyInsurance;
    const showInterest = rate * principal;
    const showPrincipalInterest = showPrincipal + showInterest;

    return { monthlyPayment, totalPMI, showPrincipalInterest };
  };

  /* HANDLE FORM SUBMIT */
  const handleFormSubmit = () => {
    const { monthlyPayment, totalPMI, showPrincipalInterest } =
      calculateMortgage();

    /* CHART VALUES */
    setEstimatedMonthlyPayment(monthlyPayment.toFixed(2));
    setPrincipalPlusInterest(showPrincipalInterest.toFixed(2));
    setShowTaxes((propertyTax / 12).toFixed(2));
    setPrivateMortgageInsurance(totalPMI.toFixed(2));
  };

  /* VALIDATION SCHEMA */
  const validationSchema5 = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string().required("Phone Number is required"),
    message: yup.string().required("Message is required"),
  });

  /* FORM VALUES HANDLING */
  const newFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      type: "PROPERTYCONTACTUS",
      propertyAddress: "",
      propertyMLSId: "",
      agentId: AGENT_ID,
    },
    validationSchema: validationSchema5,
    onSubmit: (values, { resetForm }) => {
      values.propertyAddress =
        selectedItem?.UnparsedAddress +
        ", " +
        selectedItem?.City +
        ", " +
        selectedItem?.StateOrProvince +
        ", " +
        selectedItem?.PostalCode;
      values.propertyMLSId = selectedItem?.ListingId;

      if (selectedItem?.ListOfficeName === "Amberwood Real Estate") {
        values.inHouseEmail = selectedItem?.ListOfficeEmail;
      }
      contactUs(values)
        .then((res) => {
          if (res?.status) {
            resetForm();
            console.log(res?.message);
            toast.success(res?.message);
          }
        })
        .catch((e) => {
          console.log("e-->", e);
          if (e?.response?.data?.message) {
            toast.error(e?.response?.data?.message);
          }
        })
        .finally((res) => {});
    },
  });

  const priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });

  const unitFormatter = new Intl.NumberFormat("en-US", {
    notation: "standard",
    maximumFractionDigits: 0,
  });

  var finalDOM = 0;
  const todayDate = new Date();
  const replicationDate = new Date(selectedItem?.DaysOnMarketReplicationDate);
  const timeDiff = Math.abs(todayDate - replicationDate);
  const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (selectedItem?.DaysOnMarketReplicationIncreasingYN) {
    finalDOM = selectedItem?.DaysOnMarketReplication + diffDays;
  } else {
    finalDOM = selectedItem?.DaysOnMarketReplication;
  }

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams("");
  }, [searchParams]);

  return (
    <section className="property-details">
      <ToastContainer />
      <div className="container">
        {/* PROPERTY DETAILS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="details-header"
        >
          <div className="address">
            <Button className="back-btn" onClick={() => window.history.go(-1)}>
              <UilAngleLeft size="20" color="#323232" />
            </Button>
            <div>
              <h5>{selectedItem?.UnparsedAddress}</h5>
              {selectedItem?.UnparsedAddress === null ? (
                <h5>
                  {selectedItem?.City}, {selectedItem?.StateOrProvince}{" "}
                  {selectedItem?.PostalCode}
                </h5>
              ) : (
                <p>
                  {selectedItem?.City}, {selectedItem?.StateOrProvince}{" "}
                  {selectedItem?.PostalCode}
                </p>
              )}
            </div>
          </div>
          <div className="features">
            <div className="tile">
              <p className="value">
                {selectedItem?.ListPrice === null
                  ? "-"
                  : priceFormatter.format(selectedItem?.ListPrice) === "NaN"
                  ? "-"
                  : priceFormatter.format(selectedItem?.ListPrice)}
              </p>
              <p className="label">Price</p>
            </div>
            <div
              className={`tile ${
                selectedItem?.BedroomsTotal === null ||
                selectedItem?.BedroomsTotal === 0
                  ? "d-none"
                  : "d-block"
              }`}
            >
              <p className="value">
                {selectedItem?.BedroomsTotal ? selectedItem?.BedroomsTotal : 0}
              </p>
              <p className="label">Beds</p>
            </div>
            <div
              className={`tile ${
                selectedItem?.BathroomsTotalInteger === null ||
                selectedItem?.BathroomsTotalInteger === 0
                  ? "d-none"
                  : "d-block"
              }`}
            >
              <p className="value">
                {selectedItem?.BathroomsTotalInteger
                  ? selectedItem?.BathroomsTotalInteger
                  : 0}
              </p>
              <p className="label">Baths</p>
            </div>
            <div className="tile">
              <p className="value">
                {selectedItem?.LivingArea === null
                  ? "-"
                  : unitFormatter.format(selectedItem?.LivingArea)}{" "}
                sqft
              </p>
              <p className="label">Home size</p>
            </div>
            {/* <div className="tile">
              <p className="value">
                {selectedItem?.LotSizeSquareFeet === null
                  ? "-"
                  : unitFormatter.format(selectedItem?.LotSizeSquareFeet) ===
                    "NaN"
                  ? "-"
                  : unitFormatter.format(selectedItem?.LotSizeSquareFeet)}{" "}
                sqft
              </p>
              <p className="label">Home size</p>
            </div> */}
          </div>
        </motion.div>

        {/* PROPERTY PHOTOS AND DESCRIPTION */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="row"
        >
          {/* LEFT SECTION */}
          <div className="col-xl-8 col-sm-12">
            <div className="page-left">
              {/* PROPERTY IMAGES */}
              <Swiper
                modules={[Scrollbar, Navigation, Pagination]}
                navigation={true}
                slidesPerView={1}
                spaceBetween={30}
                className="property-photos"
              >
                {selectedItem?.Media?.map((element) => {
                  if (element?.Order === 1) {
                    return (
                      <SwiperSlide
                        className="img-slider"
                        key={element?.MediaKey}
                      >
                        <img
                          src={element?.MediaURL}
                          className="property-img"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = AmberwoodBigPlaceholder;
                          }}
                          alt={selectedItem?.UnparsedAddress}
                        />
                      </SwiperSlide>
                    );
                  }
                })}
                {selectedItem?.Media?.map((data) => {
                  return (
                    <SwiperSlide className="img-slider" key={data?.MediaKey}>
                      <img
                        src={data?.MediaURL}
                        className="property-img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = AmberwoodBigPlaceholder;
                        }}
                        alt={selectedItem?.UnparsedAddress}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              {/* DESCRIPTION */}
              <div className="description">
                <ShowMoreText
                  lines={5}
                  more="Continue reading"
                  less="Show less"
                  className="content-css"
                  anchorClass="show-more-less-clickable"
                  expanded={false}
                >
                  {selectedItem?.PublicRemarks}
                </ShowMoreText>
              </div>

              {/* LISTING AGENT DETAILS */}
              <div className="listing-agent-details">
                <p>
                  Listing Agent: {selectedItem?.ListAgentFullName} DRE #
                  {selectedItem?.ListAgentStateLicense}{" "}
                  {selectedItem?.ListOfficeName}
                </p>
              </div>

              {/* PROPERTY LOCATION */}
              <div className="property-details">
                <h4>PROPERTY LOCATION</h4>
                <div className="location-map">
                  {/* <iframe
                    src={`https://www.google.com/maps/embed/v1/place?key=${YOUR_GOOGLE_MAPS_API_KEY}&q=${selectedItem?.UnparsedAddress.replace(
                      /\s/g,
                      "+"
                    )}`}
                    title="Property Location"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe> */}
                  <GoogleMap
                    id="map"
                    mapContainerStyle={mapContainerStyle}
                    zoom={16}
                    center={center}
                    options={options}
                    onLoad={onMapLoad}
                    onProjectionChanged={(e) => {
                      console.warn(e);
                    }}
                  >
                    <Marker
                      position={{
                        lat: selectedItem?.Latitude,
                        lng: selectedItem?.Longitude,
                      }}
                      icon={{
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15),
                        scaledSize: new window.google.maps.Size(30, 30),
                      }}
                    />
                    {selected ? (
                      <InfoWindow
                        position={{ lat: selected.lat, lng: selected.lng }}
                        onCloseClick={() => {
                          setSelected(null);
                        }}
                      >
                        <div>
                          <h2>
                            <p>{selected?.data?.UnparsedAddress}</p>
                            <small>
                              {selected?.data?.City},{" "}
                              {selected?.data?.StateOrProvince}{" "}
                              {selected?.data?.PostalCode}
                            </small>
                          </h2>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </GoogleMap>
                </div>
              </div>

              {/* PROPERTY DETAILS DESCRIPTION */}
              <div className="property-indetail">
                <h2>PROPERTY INFORMATION</h2>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="left-section">
                      <div className="details-box">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Air</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            {selectedItem?.Cooling &&
                            selectedItem?.Cooling.length > 0 ? (
                              <p className="value">
                                {selectedItem?.Cooling === null
                                  ? "-"
                                  : selectedItem?.Cooling?.toString()
                                      .split(/(?=[A-Z0-9])/)
                                      .join(" ")}
                              </p>
                            ) : (
                              <p className="value">-</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="details-box white">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Air Conditioning</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <p className="value">
                              {selectedItem?.Cooling &&
                              selectedItem?.Cooling?.length > 0
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Appliances</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            {selectedItem?.Appliances &&
                            selectedItem?.Appliances.length > 0 ? (
                              <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="appliances-css"
                              >
                                {selectedItem?.Appliances?.toString()
                                  .split(/(?=[A-Z0-9])/)
                                  .join(" ")}
                              </ShowMoreText>
                            ) : (
                              <p className="value">-</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="details-box white">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Construction</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            {selectedItem?.ConstructionMaterials &&
                            selectedItem?.ConstructionMaterials.length > 0 ? (
                              <p className="value">
                                {selectedItem?.ConstructionMaterials === null
                                  ? "-"
                                  : selectedItem?.ConstructionMaterials?.toString()}
                              </p>
                            ) : (
                              <p className="value">-</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Fireplace</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <p className="value">
                              {" "}
                              {selectedItem?.FireplaceFeatures &&
                              selectedItem?.FireplaceFeatures?.length > 0
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="details-box white">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Garage Spaces</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <p className="value">
                              {selectedItem?.GarageSpaces === null
                                ? "-"
                                : selectedItem?.GarageSpaces}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Heat</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            {selectedItem?.Heating &&
                            selectedItem?.Heating.length > 0 ? (
                              <p className="value">
                                {selectedItem?.Heating?.toString()
                                  .split(/(?=[A-Z0-9])/)
                                  .join(" ")}
                              </p>
                            ) : (
                              <p className="value">-</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="left-section">
                      <div className="details-box">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Lot Description</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <ShowMoreText
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="appliances-css"
                            >
                              {selectedItem?.LotFeatures === null
                                ? "-"
                                : selectedItem?.LotFeatures?.toString()
                                    .split(/(?=[A-Z0-9])/)
                                    .join(" ")}
                            </ShowMoreText>
                          </div>
                        </div>
                      </div>
                      <div className="details-box white">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Parking</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <ShowMoreText
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="appliances-css"
                            >
                              {selectedItem?.ParkingFeatures === null
                                ? "-"
                                : selectedItem?.ParkingFeatures?.toString()
                                    .split(/(?=[A-Z0-9])/)
                                    .join(" ")}
                            </ShowMoreText>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Pool Description</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <ShowMoreText
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="appliances-css"
                            >
                              {selectedItem?.PoolFeatures === null
                                ? "-"
                                : selectedItem?.PoolFeatures?.toString()
                                    .split(/(?=[A-Z0-9])/)
                                    .join(" ")}
                            </ShowMoreText>
                          </div>
                        </div>
                      </div>
                      <div className="details-box white">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Stories</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <p className="value">
                              {selectedItem?.Stories
                                ? selectedItem?.Stories
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Style</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <p className="value">
                              {selectedItem?.ArchitecturalStyle
                                ? selectedItem?.ArchitecturalStyle
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="details-box white">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">Subdivision</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <p className="value">
                              {selectedItem?.SubdivisionName
                                ? selectedItem?.SubdivisionName
                                : "Not Listed"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="details-box">
                        <div className="row">
                          <div className="col-xl-6 col-6">
                            <p className="label">View Description</p>
                          </div>
                          <div className="col-xl-6 col-6">
                            <ShowMoreText
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="appliances-css"
                            >
                              {selectedItem?.View === null
                                ? "-"
                                : selectedItem?.View?.toString()
                                    .split(/(?=[A-Z0-9])/)
                                    .join(" ")}
                            </ShowMoreText>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* MORTGAGE CALCULATOR */}
              <div className="mortgage-calculator">
                <h2>MORTGAGE CALCULATOR</h2>
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-sm-12">
                    <div className="monthly-payment-box">
                      <span>ESTIMATED MONTHLY PAYMENT</span>
                      <h2>
                        {currencyFormatter.format(estimatedMonthlyPayment)}
                      </h2>
                      <div className="total-cost">
                        <p>Principal + Interest</p>
                        <p>{currencyFormatter.format(principalPlusInterest)}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-sm-12">
                        <div className="monthly-tax-box">
                          <span>MONTHLY TAX</span>
                          <h2>{currencyFormatter.format(showTaxes)}</h2>
                        </div>
                      </div>
                      <div className="col-xl-6 col-sm-12">
                        <div className="monthly-tax-box">
                          <span>MONTHLY INSURANCE</span>
                          <h2>
                            {currencyFormatter.format(privateMortgageInsurance)}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-sm-12">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Purchase Price</label>
                            <input
                              type="number"
                              name="purchasePrice"
                              placeholder="Purchase Price"
                              className={`form-control ${
                                formik.errors.purchasePrice &&
                                formik.touched.purchasePrice
                                  ? "validation-border"
                                  : ""
                              }`}
                              value={formik.values.purchasePrice}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Down Payment</label>
                            <input
                              type="number"
                              name="downPayment"
                              placeholder="Down Payment"
                              className={`form-control ${
                                formik.errors.downPayment &&
                                formik.touched.downPayment
                                  ? "validation-border"
                                  : ""
                              }`}
                              value={formik.values.downPayment}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Term</label>
                            <Select
                              options={termOptions}
                              className="react-select"
                              classNamePrefix="custom-select"
                              placeholder="Select Term"
                              aria-label="Loan Term"
                              value={formik.values.loanTerm}
                              onChange={(e) =>
                                formik.setFieldValue("loanTerm", e)
                              }
                            />
                          </div>
                          {formik.errors.term && formik.touched.term && (
                            <small style={{ color: "red" }}>
                              {formik.errors.term}
                            </small>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">Interest Rate</label>
                            <input
                              type="text"
                              name="interestRate"
                              placeholder="Interest Rate"
                              className={`form-control ${
                                formik.errors.interestRate &&
                                formik.touched.interestRate
                                  ? "validation-border"
                                  : ""
                              }`}
                              value={formik.values.interestRate}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Property Tax (yearly)
                            </label>
                            <input
                              type="number"
                              name="propertyTax"
                              placeholder="Property Tax"
                              className={`form-control ${
                                formik.errors.propertyTax &&
                                formik.touched.propertyTax
                                  ? "validation-border"
                                  : ""
                              }`}
                              value={formik.values.propertyTax}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Insurance (yearly)
                            </label>
                            <input
                              type="number"
                              name="insurance"
                              placeholder="Insurance"
                              className={`form-control ${
                                formik.errors.insurance &&
                                formik.touched.insurance
                                  ? "validation-border"
                                  : ""
                              }`}
                              value={formik.values.insurance}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              HOA Fees (monthly)
                            </label>
                            <input
                              type="number"
                              name="hoaFees"
                              placeholder="HOA Fees"
                              className={`form-control ${
                                formik.errors.hoaFees && formik.touched.hoaFees
                                  ? "validation-border"
                                  : ""
                              }`}
                              value={formik.values.hoaFees}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-end">
                        <div className="col-xl-3 col-6">
                          <Button
                            className="reset-btn"
                            onClick={formik.handleReset}
                          >
                            RESET
                          </Button>
                        </div>
                        <div className="col-xl-3 col-6">
                          <Button className="calculate-btn" type="submit">
                            CALCULATE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* RIGHT SECTION */}
          <div className="col-xl-4 col-sm-12">
            <div className="page-right">
              {/* DETAILS */}
              <div className="official-details">
                <p className="last-updated">
                  LAST UPDATED:{" "}
                  {moment(Date(selectedItem?.ModificationTimestamp)).format(
                    "MM/DD/yyyy hh:mm:ss A"
                  )}
                </p>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">Status</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">
                        {selectedItem?.MlsStatus === null
                          ? "-"
                          : selectedItem?.MlsStatus}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">MLS #</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">
                        {selectedItem?.ListingId === null
                          ? "-"
                          : selectedItem?.ListingId}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">Days on Market</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">
                        {finalDOM === null ? "-" : finalDOM}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">Taxes</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">
                        {selectedItem?.TaxAnnualAmount
                          ? selectedItem?.TaxAnnualAmount
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">HOA Fees</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">
                        $
                        {selectedItem?.AssociationFee === null
                          ? 0
                          : selectedItem?.AssociationFee}{" "}
                        / month
                      </p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">Condo/Co-op Fees</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">-</p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">Lot Size</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">
                        {selectedItem?.LotSizeArea === null
                          ? "-"
                          : unitFormatter.format(
                              selectedItem?.LotSizeArea
                            )}{" "}
                        sqft /{" "}
                        {selectedItem?.LotSizeAcres === null
                          ? "-"
                          : parseFloat(selectedItem?.LotSizeAcres).toFixed(
                              2
                            )}{" "}
                        Acres
                      </p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">MLS Type</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">
                        {selectedItem?.PropertyType.split(/(?=[A-Z])/).join(
                          " "
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">Year Built</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">{selectedItem?.YearBuilt}</p>
                    </div>
                  </div>
                </div>
                <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">County</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">{selectedItem?.CountyOrParish}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* CONTACT COMPASS AGENT */}
              <div className="contact-compass-agent">
                <h2>SCHEDULE A TOUR</h2>
                <form
                  className="contact-form"
                  onSubmit={newFormik.handleSubmit}
                >
                  <p>11060 Artesia Blvd Suite G, Cerritos, CA 90703, USA</p>
                  <p style={{ marginBottom: "10px" }}>
                    Phone:{" "}
                    <a href="tel:+15627992388" style={{ color: "#008080" }}>
                      +1 562-799-2388
                    </a>
                  </p>
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control ${
                        newFormik.errors.name && newFormik.touched.name
                          ? "validation-border"
                          : ""
                      }`}
                      name="name"
                      value={newFormik.values.name}
                      onChange={newFormik.handleChange}
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={`form-control ${
                        newFormik.errors.email && newFormik.touched.email
                          ? "validation-border"
                          : ""
                      }`}
                      name="email"
                      value={newFormik.values.email}
                      onChange={newFormik.handleChange}
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="form-group">
                    <PhoneInput
                      placeholder="Phone Number"
                      type="text"
                      defaultCountry="US"
                      name="phone"
                      value={newFormik.values.phone}
                      onChange={(phone) =>
                        newFormik.setFieldValue("phone", phone)
                      }
                      className={`phone-number-select ${
                        newFormik.errors.phone && newFormik.touched.phone
                          ? "validation-border"
                          : ""
                      }`}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      type="text"
                      rows="4"
                      className={`form-control ${
                        newFormik.errors.message && newFormik.touched.message
                          ? "validation-border"
                          : ""
                      }`}
                      name="comments"
                      placeholder="I would like more information about this property"
                      value={newFormik.values.message}
                      onChange={(message) =>
                        newFormik.setFieldValue("message", message.target.value)
                      }
                    />
                  </div>
                  <Button type="submit" className="submit-btn">
                    Send Message
                  </Button>
                  <span>
                    By clicking Send Message, I agree that Amberwood agent may
                    contact me by phone or text message including by automated
                    means about real estate services, and that I can access real
                    estate services without providing my phone number. I
                    acknowledge that I have read and agree to the{" "}
                    <Link
                      to="/privacy-policy"
                      style={{ textDecoration: "none" }}
                    >
                      Privacy Policy
                    </Link>
                    .
                  </span>
                </form>
              </div>
            </div>
          </div>
        </motion.div>

        {/* CONDITIONS TEXT */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="conditions-text mt-2"
        >
          <p>
            We respect your online privacy and will never spam you. By
            submitting this form with your telephone number you are consenting
            for Amberwood Mortgage to contact you even if your name is on a
            Federal or State "Do not call List".
          </p>
          <p>Listed with RE/MAX Accord</p>
          <p>
            The multiple listing data appearing on this website, or contained in
            reports produced therefrom, is owned and copyrighted by California
            Regional Multiple Listing Service, Inc. ("CRMLS") and is protected
            by all applicable copyright laws. All listing data, including but
            not limited to square footage and lot size is believed to be
            accurate, but the listing Agent, listing Broker and CRMLS and its
            affiliates do not warrant or guarantee such accuracy. The viewer
            should independently verify the listed data prior to making any
            decisions based on such information by personal inspection and/or
            contacting a real estate professional.
          </p>
          <p>
            Based on information from CARETS as of 4/2/23 12:13 AM PDT. The
            information being provided by CRMLS & CARETS is for the visitor's
            personal, noncommercial use and may not be used for any purpose
            other than to identify prospective properties visitor may be
            interested in purchasing. The data contained herein is copyrighted
            by CARETS, CLAW, CRISNet MLS, i-Tech MLS, PSRMLS and/or VCRDS and is
            protected by all applicable copyright laws. Any dissemination of
            this information is in violation of copyright laws and is strictly
            prohibited.
          </p>
          <p>
            Any property information referenced on this website comes from the
            Internet Data Exchange (IDX) program of CRISNet MLS and/or CARETS.
            All data, including all measurements and calculations of area, is
            obtained from various sources and has not been, and will not be,
            verified by broker or MLS. All information should be independently
            reviewed and verified for accuracy. Properties may or may not be
            listed by the office/agent presenting the information.
          </p>
          <p>This IDX solution is (c) Diverse Solutions 2023.</p>
        </motion.div>
      </div>
    </section>
  );
};

export default PropertyDetails;
